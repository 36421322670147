@font-face {
    font-family: 'Domaine Disp';
    src: url('DomaineDisp-Bold.woff2') format('woff2'),
        url('DomaineDisp-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-BoldItalic.woff2') format('woff2'),
        url('Inter-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Italic.woff2') format('woff2'),
        url('Inter-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Medium.woff2') format('woff2'),
        url('Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-MediumItalic.woff2') format('woff2'),
        url('Inter-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

